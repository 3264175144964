<template>
  <main>
    <section v-if="!$store.user.isSupplier" class="cta">
      <div class="cta__bg"></div>
      <div class="cta__center">
        <h2 class="cta__heading">Find your next footwear supplier the easy way</h2>
        <h3 class="cta__subheading">
          We search among
          <strong>{{ $store.suppliers.counter }} suppliers</strong> to find the best ones matching your specific needs -
          at no cost!
        </h3>
        <form action="#" method="post" class="cta__form" @submit.prevent="findSuppliers(email)">
          <div v-if="!$store.user.loggedIn" class="cta__input field__input-wrapper">
            <div class="field__input-wrapper-inner">
              <input
                class="field__input"
                type="email"
                placeholder="Enter your e-mail address..."
                required
                v-model="email"
              />
            </div>
          </div>
          <fs-button-main
            class="cta__button"
            type="submit"
            :long="true"
            :size="$store.user.loggedIn ? 'huge' : 'large'"
            :input-like="!$store.user.loggedIn"
            :loading="ctaFormLoading"
            >Start finding new suppliers</fs-button-main
          >
        </form>
        <!-- LinkedIn disabled because broken. 2024-02-23 -->
        <div v-if="false && !$store.user.loggedIn" class="cta__social">
          <span class="cta__social-label">Or sign in using</span>
          <div class="cta__social-buttons">
            <form class="cta__social-button" method="post" action="/api/account/externalLogin">
              <input type="hidden" name="returnUrl" value="/quotation" />
              <button
                class="link link--button"
                type="submit"
                name="provider"
                value="LinkedIn"
                title="Log in using your LinkedIn account"
              >
                <i class="link__icon" v-html="svg.linkedIn"></i>LinkedIn
              </button>
            </form>
            <!-- Removed until we have support for it! -->
            <!--
          <a href="#" class="cta__social-button link">
            <i class="link__icon" v-html="svg.google"></i>
            Google
          </a>
          <a href="#" class="cta__social-button link">
            <i class="link__icon" v-html="svg.microsoft"></i>
            Microsoft
          </a>
          --></div>
        </div>
      </div>
      <div class="cta__bottom">
        <div class="cta__bottom-logos">
          <div class="cta__bottom-logo cta__bottom-logo--sneaky-steve" v-html="svg.brandLogos.sneakySteve"></div>
          <div class="cta__bottom-logo cta__bottom-logo--zalando" v-html="svg.brandLogos.zalando"></div>
          <div class="cta__bottom-logo cta__bottom-logo--nakd" v-html="svg.brandLogos.nakd"></div>
          <div class="cta__bottom-logo cta__bottom-logo--axel-arigato" v-html="svg.brandLogos.axelArigato"></div>
          <div class="cta__bottom-logo cta__bottom-logo--hm" v-html="svg.brandLogos.hm"></div>
          <div class="cta__bottom-logo cta__bottom-logo--ralph-lauren" v-html="svg.brandLogos.ralphLauren"></div>
          <div class="cta__bottom-logo cta__bottom-logo--relaxo" v-html="svg.brandLogos.relaxo"></div>
          <div class="cta__bottom-logo cta__bottom-logo--vera-bradley" v-html="svg.brandLogos.veraBradley"></div>
          <div class="cta__bottom-logo cta__bottom-logo--arkk" v-html="svg.brandLogos.arkk"></div>
          <div class="cta__bottom-logo cta__bottom-logo--eytys" v-html="svg.brandLogos.eytys"></div>
        </div>
        <strong v-if="!$store.user.loggedIn || !$store.user.isBuyer" class="cta__bottom-text">
          Supplier? Register
          <router-link class="link" :to="{ name: 'supplierRegister' }">here</router-link> and we will send you business
          proposals from brands we work with
        </strong>
        <strong v-if="$store.user.loggedIn && $store.user.isBuyer" class="cta__bottom-text">
          We work with some of the most well-known brands around the world
        </strong>
        <!--<div class="cta__bottom-scroll" v-scroll-to="{el:'.search', offset: -80}">
          Scroll
        </div>-->
      </div>
    </section>
    <section class="search" id="search">
      <h2 class="search__heading">
        Search among
        <strong>{{ $store.suppliers.counter }}</strong> footwear suppliers
      </h2>
      <h3 class="search__subheading">Select style below to start browse our supplier directory</h3>
      <ul class="categories">
        <li class="categories__item">
          <router-link :to="{ name: 'footwearSuppliers', query: { footwearTypes: 'Shoes' } }" class="categories__link">
            <img src="../img/summer/footwear/shoes.jpg" class="categories__img" alt />
            <strong class="categories__title">Shoes</strong>
          </router-link>
        </li>
        <li class="categories__item">
          <router-link :to="{ name: 'footwearSuppliers', query: { footwearTypes: 'Boots' } }" class="categories__link">
            <img src="../img/summer/footwear/boots.jpg" class="categories__img" alt />
            <strong class="categories__title">Boots</strong>
          </router-link>
        </li>
        <li class="categories__item">
          <router-link
            :to="{ name: 'footwearSuppliers', query: { footwearTypes: 'Sneakers' } }"
            class="categories__link"
          >
            <img src="../img/summer/footwear/sneakers.jpg" class="categories__img" alt />
            <strong class="categories__title">Sneakers</strong>
          </router-link>
        </li>
        <li class="categories__item">
          <router-link
            :to="{ name: 'footwearSuppliers', query: { footwearTypes: 'Heeled Shoes' } }"
            class="categories__link"
          >
            <img src="../img/summer/footwear/heeledshoes.jpg" class="categories__img" alt />
            <strong class="categories__title">Heeled Shoes</strong>
          </router-link>
        </li>
        <li class="categories__item">
          <router-link
            :to="{ name: 'footwearSuppliers', query: { footwearTypes: 'Espadrilles' } }"
            class="categories__link"
          >
            <img src="../img/summer/footwear/espadrilles.jpg" class="categories__img" alt />
            <strong class="categories__title">Espadrilles</strong>
          </router-link>
        </li>
        <li class="categories__item">
          <router-link
            :to="{ name: 'footwearSuppliers', query: { footwearTypes: 'Moccasin' } }"
            class="categories__link"
          >
            <img src="../img/summer/footwear/moccasin.jpg" class="categories__img" alt />
            <strong class="categories__title">Moccasins</strong>
          </router-link>
        </li>
        <li class="categories__item">
          <router-link
            :to="{ name: 'footwearSuppliers', query: { footwearTypes: 'Flip Flops' } }"
            class="categories__link"
          >
            <img src="../img/summer/footwear/flipflops.jpg" class="categories__img" alt />
            <strong class="categories__title">Flip Flops</strong>
          </router-link>
        </li>
        <li class="categories__item">
          <router-link
            :to="{ name: 'footwearSuppliers', query: { footwearTypes: 'Ballet Flats' } }"
            class="categories__link"
          >
            <img src="../img/summer/footwear/balletflats.jpg" class="categories__img" alt />
            <strong class="categories__title">Ballet Flats</strong>
          </router-link>
        </li>
      </ul>
    </section>
    <section class="process">
      <div class="process__container">
        <h2 class="process__heading">Get tailor-made offers from vetted suppliers</h2>
        <h3 class="process__subheading">Submit your request in 5 minutes and we will handle the rest</h3>
        <div class="process-flow">
          <div class="process-flow__step">
            <div class="process-flow__icon process-flow__icon--large" v-html="svg.iconIdea"></div>
            <div class="process-flow__info">
              <strong class="process-flow__title">Submit to FindSourcing</strong>
              <p class="process-flow__text">Brand registers quotation request on FindSourcing.com</p>
            </div>
          </div>
          <div class="process-flow__step">
            <div class="process-flow__icon" v-html="svg.iconSearch"></div>
            <div class="process-flow__info">
              <strong class="process-flow__title">Global sourcing</strong>
              <p class="process-flow__text">
                FindSourcing initiates sourcing among suppliers and contacts according to request.
              </p>
            </div>
          </div>
          <div class="process-flow__step">
            <div class="process-flow__icon process-flow__icon--large" v-html="svg.iconInvoice"></div>
            <div class="process-flow__info">
              <strong class="process-flow__title">Suppliers submit offers</strong>
              <p class="process-flow__text">Suppliers submit their offers.</p>
            </div>
          </div>
          <div class="process-flow__step">
            <div class="process-flow__icon" v-html="svg.iconInformation"></div>
            <div class="process-flow__info">
              <strong class="process-flow__title">Offer review</strong>
              <p class="process-flow__text">
                FindSourcing reviews and curates offers. Only the best are selected and presented to the Buyer.
              </p>
            </div>
          </div>
          <div class="process-flow__step">
            <div class="process-flow__icon process-flow__icon--large" v-html="svg.iconFeedback"></div>
            <div class="process-flow__info">
              <strong class="process-flow__title">Buyer selection</strong>
              <p class="process-flow__text">
                Buyer selects suppliers to proceed with. Communication is handed over, buyer – manufacturer.
              </p>
            </div>
          </div>
        </div>
      </div>
      <fs-button-main class="process__action" :to="{ name: 'quotation' }" :long="true" size="large" exact
        >Submit your request</fs-button-main
      >
    </section>
    <section class="benefits">
      <h2 v-if="!$store.user.loggedIn" class="benefits__heading">
        Find better partners than possible the traditional way
      </h2>
      <h3 v-if="!$store.user.loggedIn" class="benefits__subheading">
        All the benefits of modern sourcing in one platform
      </h3>
      <div v-if="!$store.user.loggedIn" class="benefits__container">
        <div class="benefits__group">
          <h4 class="benefits__group-heading">Buyers</h4>
          <ul class="benefits__list">
            <li class="benefits__item">
              <span class="benefits__icon" v-html="svg.check"></span>Access to a global database of verified footwear
              manufacturers
            </li>
            <li class="benefits__item">
              <span class="benefits__icon" v-html="svg.check"></span>Industry specific search, matching and filter
              functions
            </li>
            <li class="benefits__item">
              <span class="benefits__icon" v-html="svg.check"></span>Send in a request and get detailed offers from the
              best suppliers
            </li>
            <li class="benefits__item">
              <span class="benefits__icon" v-html="svg.check"></span>Intuitive usage, secure communication with
              responses from humans
            </li>
          </ul>
          <fs-button-main class="benefits__group-button" :to="{ name: 'quotation' }" exact>Free sign up</fs-button-main>
        </div>
        <div class="benefits__group">
          <h4 class="benefits__group-heading">Suppliers</h4>
          <ul class="benefits__list">
            <li class="benefits__item">
              <span class="benefits__icon" v-html="svg.check"></span>Your own digital supplier profie searchable by
              buyers
            </li>
            <li class="benefits__item">
              <span class="benefits__icon" v-html="svg.check"></span>Find new business opportunities that best match
              your expertise
            </li>
            <li class="benefits__item">
              <span class="benefits__icon" v-html="svg.check"></span>Submit offers to requests from vetted buyers
            </li>
            <li class="benefits__item">
              <span class="benefits__icon" v-html="svg.check"></span>Benchmark your offers with market average for KPIs
              such as price &amp; leadtime
            </li>
          </ul>
          <fs-button-main class="benefits__group-button" :to="{ name: 'supplierRegister' }" exact
            >Free sign up</fs-button-main
          >
        </div>
      </div>
      <section class="partners">
        <h2 class="partners__heading" :class="{ 'partners__heading--big': $store.user.loggedIn }">
          Be part of a modern, open and connected ecosystem
        </h2>
        <h3 class="partners__subheading">We have partnered up with some of the top players in the industry</h3>
        <div class="partners__container">
          <div class="partners__partner">
            <a
              class="partners__image-link"
              href="https://fdra.org/"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'FDRA click')"
            >
              <img class="partners__image" src="../img/partner-logos/fdra-mono.png" />
              <img class="partners__image partners__image--on-top" src="../img/partner-logos/fdra.png" />
            </a>
            <p class="partners__text">
              FDRA - Footwear Distributors and Retailers of America, is by far the largest footwear association in North
              America, representing 90%+ of brands, importers and suppliers. Every year, FDRA organizes the Global Shoe
              Sourcing event, in which FindSourcing is a speaker and partner. Click
              <a
                class="link link--dark"
                href="https://www.shoesourcingsummit.com/"
                target="_blank"
                rel="noopener noreferrer"
                @click="$ga.event('partners', 'FDRA click')"
                >here</a
              >
              to get to the event website.
            </p>
            <a
              class="partners__link link link--button-like link--dark"
              href="https://fdra.org/"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'FDRA click')"
              >Visit FDRA</a
            >
          </div>
          <div class="partners__partner">
            <a
              class="partners__image-link"
              href="https://www.apiccaps.pt/"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'APICCAPS click')"
            >
              <img class="partners__image" src="../img/partner-logos/apiccaps-mono.png" />
              <img class="partners__image partners__image--on-top" src="../img/partner-logos/apiccaps.png" />
            </a>
            <p class="partners__text">
              APICCAPS - the Portuguese Footwear, Components, Leather Good Manufacturers' Association - represents some
              of the very best European manufacturers out there. We're very happy to officially be able to promote these
              manufacturers and strengthen our European expertise further. Click
              <router-link
                class="link link--dark"
                :to="{ name: 'footwearSuppliers', query: { associations: 'APICCAPS (Portugal)' } }"
                >here</router-link
              >
              to find a list of APICCAPS' members!
            </p>
            <a
              class="partners__link link link--button-like link--dark"
              href="https://www.apiccaps.pt/"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'APICCAPS click')"
              >Visit APICCAPS</a
            >
          </div>
          <div class="partners__partner">
            <a
              class="partners__image-link"
              href="http://www.lefaso.org.vn/default.aspx?ID1=2"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'Lefaso click')"
            >
              <img class="partners__image" src="../img/partner-logos/lefaso-mono.png" />
              <img class="partners__image partners__image--on-top" src="../img/partner-logos/lefaso.png" />
            </a>
            <p class="partners__text">
              We've teamed up with LEFASO, the Vietnam Leather, Footwear and Handbag Association to be able provide
              brands with the best Vietnamese manufacturers around. Vietnam is one of the rising stars in footwear
              manufacturing and we are eager to be able to promote LEFASO's manufacturers and find them great
              opportunities for growth!<!-- Click here to find a complete list of LEFASO's members!-->
            </p>
            <a
              class="partners__link link link--button-like link--dark"
              href="http://www.lefaso.org.vn/default.aspx?ID1=2"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'Lefaso click')"
              >Visit LEFASO</a
            >
          </div>
          <div v-if="$store.general.satra" class="partners__partner">
            <a
              class="partners__image-link"
              href="https://www.satra.com/"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'SATRA click')"
            >
              <img class="partners__image" src="../img/partner-logos/satra-mono.png" />
              <img class="partners__image partners__image--on-top" src="../img/partner-logos/satra.png" />
            </a>
            <p class="partners__text">
              SATRA is a leading technical authority for footwear and leather. SATRA members get exclusive access to
              SATRA test methods, accreditation, consultancy, research facilities, technical training and factory
              production management systems. At FindSourcing, we utilize SATRA's knowledge to help you find the best
              supplier! Click
              <router-link class="link link--dark" :to="{ name: 'footwearSuppliers', query: { associations: 'SATRA' } }"
                >here</router-link
              >
              to find a list of SATRA's members!
            </p>
            <a
              class="partners__link link link--button-like link--dark"
              href="https://www.satra.com/"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'SATRA click')"
              >Visit SATRA</a
            >
          </div>
          <div class="partners__partner">
            <a
              class="partners__image-link"
              href="https://sourcingplayground.com/"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'Sourcing Playground click')"
            >
              <img class="partners__image" src="../img/partner-logos/sourcing-playground-mono.png" />
              <img class="partners__image partners__image--on-top" src="../img/partner-logos/sourcing-playground.png" />
            </a>
            <p class="partners__text">
              At Sourcing Playground you can connect with trusted manufacturers and suppliers. To give both our users
              the best possible experience, FindSourcing handles any footwear related request on Sourcing Playground
              while we recommend our users to try Sourcing Playground for any non-footwear related request.
            </p>
            <a
              class="partners__link link link--button-like link--dark"
              href="https://sourcingplayground.com/"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'Sourcing Playground click')"
              >Visit Sourcing Playground</a
            >
          </div>
          <div class="partners__partner">
            <a
              class="partners__image-link"
              href="https://www.ciceg.org"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'CICEG click')"
            >
              <img class="partners__image" src="../img/partner-logos/ciceg-mono.png" />
              <img class="partners__image partners__image--on-top" src="../img/partner-logos/ciceg.png" />
            </a>
            <p class="partners__text">
              CICEG and Shoes from Mexico is the industry organization in Mexico to bring all the amazing suppliers in
              the region closer to you. Mexico has gotten plenty of attention lately and we are getting more and more
              requests for made in Mexico, for a reason! Check out the suppliers connected to CICEG
              <router-link
                class="link link--dark"
                :to="{ name: 'footwearSuppliers', query: { associations: 'CICEG (Mexico)' } }"
                >here</router-link
              >.
            </p>
            <a
              class="partners__link link link--button-like link--dark"
              href="https://www.ciceg.org"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'CICEG click')"
            >
              Visit CICEG
            </a>
          </div>
          <div class="partners__partner">
            <a
              class="partners__image-link"
              href="http://www.globalfootwear.cn"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'GFSS click')"
            >
              <img class="partners__image" src="../img/partner-logos/gfss-mono.png" />
              <img class="partners__image partners__image--on-top" src="../img/partner-logos/gfss.png" />
            </a>
            <p class="partners__text">
              To stay on top of the latest within sustainability is key to be a leader in today's environment. GFSS, the
              Global Footwear Sustainability Summit, attracts hundreds of top officers and industry leaders from leading
              footwear brands, OEMs, sourcing &amp; retailing groups, materials suppliers as well as relevant government
              officials and associations.
            </p>
            <a
              class="partners__link link link--button-like link--dark"
              href="http://www.globalfootwear.cn"
              target="_blank"
              rel="noopener noreferrer"
              @click="$ga.event('partners', 'GFSS click')"
            >
              Visit GFSS
            </a>
          </div>
        </div>
      </section>
    </section>
    <section v-if="isMounted" class="knowledge-bank">
      <h2 class="knowledge-bank__heading">Dive into our knowledge bank</h2>
      <h3 class="knowledge-bank__subheading">
        All the critical knowledge you need to become a footwear production expert
      </h3>
      <div v-if="isMounted">
        <Swiper :options="swiperOptions" ref="articlesCarousel">
          <!-- Slides -->
          <Swiper-slide v-for="article in carouselFeaturedArticles" :key="article.id">
            <div class="slide__content">
              <div class="slide__image-container">
                <img class="slide__image" :src="`${$blobUrl}/images/${article.previewImg}`" :alt="article.title" />
              </div>
              <div class="slide__info">
                <h5 class="slide__heading">{{ article.title }}</h5>
                <p class="slide__intro">{{ article.description }}</p>
                <router-link
                  class="link link--dark link--button-like slide__link"
                  :to="{ name: 'article', params: { category: article.categorySlug, slug: article.slug } }"
                  :key="article.id"
                  >Read more</router-link
                >
              </div>
            </div>
          </Swiper-slide>
          <!-- Controls -->
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </Swiper>
      </div>
      <div v-if="category.articles.length > 3" class="category" v-for="category in $store.knowledgebank.featured">
        <h4 class="category__heading">{{ category.title }}</h4>
        <div class="category__articles">
          <div class="item" v-for="article in category.articles.slice(0, 4)">
            <div
              class="item__image"
              :style="{ backgroundImage: `url(${$blobUrl}/images/${article.previewImg})` }"
            ></div>
            <h5 class="item__heading">{{ article.title }}</h5>
            <p class="item__intro">{{ article.description }}</p>
            <router-link
              class="link link--dark link--button-like item__link"
              :to="{ name: 'article', params: { category: category.slug, slug: article.slug } }"
              :key="article.id"
              >Read more</router-link
            >
          </div>
        </div>
      </div>
      <fs-button-main class="knowledge-bank__cta" :long="true" size="large" :to="{ name: 'articles' }" exact
        >View all articles</fs-button-main
      >
    </section>
  </main>
</template>

<script>
const swiper = () => import('vue-swiper-ave2/src/swiper.vue');
const swiperSlide = () => import('vue-swiper-ave2/src/slide.vue');

import FsButtonMain from '@components/buttons/fs-button-main.vue';

// Icons
import linkedIn from '../img/in-icon.svg';
import google from '../img/google-icon.svg';
import microsoft from '../img/microsoft-icon.svg';

import iconFeedback from '../img/qi-feedback.svg';
import iconIdea from '../img/qi-idea.svg';
import iconInformation from '../img/qi-information.svg';
import iconInvoice from '../img/qi-invoice.svg';
import iconSearch from '../img/qi-search.svg';
import check from '../img/baseline-check_circle_outline-24px.svg';
import arrowRight from '../img/baseline-keyboard_arrow_right-24px.svg';

// Brand logos
import arkk from '../img/brand-logos/ARKK.svg';
import axelArigato from '../img/brand-logos/AxelArigato.svg';
import eytys from '../img/brand-logos/Eytys.svg';
import hm from '../img/brand-logos/HM.svg';
import nakd from '../img/brand-logos/NAKD.svg';
import ralphLauren from '../img/brand-logos/RalphLauren.svg';
import relaxo from '../img/brand-logos/Relaxo.svg';
import sneakySteve from '../img/brand-logos/SneakySteve.svg';
import veraBradley from '../img/brand-logos/VeraBradley.svg';
import zalando from '../img/brand-logos/zalando.svg';

export default {
  load({ store, route }) {
    return Promise.all([store.suppliers.count(), store.knowledgebank.loadFeatured()]);
  },
  components: { FsButtonMain, Swiper: swiper, SwiperSlide: swiperSlide },
  data() {
    return {
      svg: {
        linkedIn,
        google,
        microsoft,
        iconFeedback,
        iconIdea,
        iconInformation,
        iconInvoice,
        iconSearch,
        check,
        arrowRight,
        brandLogos: {
          arkk,
          axelArigato,
          eytys,
          hm,
          nakd,
          ralphLauren,
          relaxo,
          sneakySteve,
          veraBradley,
          zalando,
        },
      },
      email: '',
      ctaFormLoading: false,
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        keyboard: true,
        loop: true,
        autoHeight: true,
      },
      isMounted: false,
    };
  },
  computed: {
    carouselFeaturedArticles() {
      // Return the 2 first articles from each category as a flat array, with the
      // first featured article of every category first, and then all number 2s.
      return this.$store.knowledgebank.featured.reduce((articles, category, index) => {
        articles.splice(index, 0, category.articles[0]);
        articles.push(category.articles[1]);

        return articles;
      }, []);
    },
  },
  methods: {
    async findSuppliers(email) {
      // Redirect if logged in
      if (this.$store.user.loggedIn) {
        this.$router.push({ name: 'quotation' });
        return;
      }

      // Avoid multiple simultaneous calls to avoid registering multiple
      // users and other types of issues stemming from that
      if (this.ctaFormLoading) return;

      this.ctaFormLoading = true;

      let { success } = await this.$store.user.registerBuyerWithoutPassword(email);

      if (!success) {
        this.ctaFormLoading = false;

        this.$notify({
          type: 'error',
          title: `Could not register user with ${email} - please try and log in.`,
        });
      }

      this.$router.push({ name: 'quotation' });
    },
  },
  mounted() {
    // Temp hack for main padding
    try {
      document.getElementsByClassName('main')[0].classList.add('main--padding');
    } catch (e) {}

    // Lazy load articlesCarousel
    this.isMounted = true;

    if (this.$route.query.satra) {
      this.$store.general.satra = true;
    }
  },
  beforeDestroy() {
    // Temp hack for main padding
    try {
      document.getElementsByClassName('main')[0].classList.remove('main--padding');
    } catch (e) {}
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

// Based content ~swiper/src/swiper.scss
@import 'swiper/src/scss/functions';

$themeColor: $c-dark !default;
$colors: (
  white: #ffffff,
  black: #000000,
) !default;

@import 'swiper/src/components/core/core';
@import 'swiper/src/components/navigation/navigation';

.home {
  .cta {
    position: relative;
    margin: -2.25rem -1rem 0 -1rem;
    padding: 2rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    -ms-flex-pack: center;
    -ms-flex-align: center;
    justify-content: center;
    text-align: center;

    // Based on global-header for rem margin (taking header height into
    // consideration).
    // Note change from vh -> vmin when moving from mobile -> tablet/desktop
    // In portrait tablet/desktop we don't want it to take up all vertical
    // space since it looks horribly elongated;

    min-height: calc(100vh - 3.5rem);
    @include tablet-min {
      // Bottom padding needs to be added to account for
      // bottom section (which is not accounted for since
      // absolutely positioned), to avoid having the bottom
      // section ending up on top of the rest on short (low
      // height) screens.
      //
      // Bottom text is 3.7rem
      // Scroll part is ~3rem (10px => 0.75rem)
      // An additional 1.25rem for marign have been added
      //
      // Top padding added to balance out the bottom padding
      // to keep the center piece centered;
      padding-top: 8.5%;
      padding-bottom: 13rem;
      min-height: calc(100vmin - 4rem);
    }
    @include desktop-min {
      min-height: calc(100vmin - 4.375rem);
    }

    &__bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('../img/register_bg.jpg');
      background-size: cover;
      background-position: center;
      -webkit-filter: grayscale(100%);
      filter: gray;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(rgba($c-light, 0.7), rgba($c-light, 0.9), $c-light);
      }
      /*&--easter {
        -webkit-filter: grayscale(100%) brightness(80%) sepia(190%) hue-rotate(5deg) saturate(310%) contrast(1);
        filter: grayscale(100%) brightness(80%) sepia(190%) hue-rotate(5deg) saturate(310%) contrast(1);
      }*/
    }
    /*&__easter-eggs-bg {
      position: absolute;
      bottom: 0rem;
      right: 1vw;
      height: calc(4rem + 4vw);
      width: calc(12rem + 10vw);
      background-image: url("../img/easter/eggs.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.8;
    }*/
    &__center {
      padding: 1rem 0 1rem 0;
      position: relative;
      margin-top: auto;
      margin-bottom: auto;
      @include tablet-min {
        margin: 0;
      }
    }
    &__heading {
      @include heading--huge;
      margin-top: 0;
      padding: 0 1rem;
    }
    &__subheading {
      @include subtitle;
      text-align: center;
      margin-bottom: 1rem;
      padding: 0 1rem;
      @include mobile-only {
        margin-top: 0.75rem;
      }
    }
    &__form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 2rem auto 0;
      max-width: math.div(32rem, 1.6);
      @include tablet-min {
        flex-wrap: nowrap;
        margin-top: 3.375rem;
        max-width: 100%;
      }
    }
    &__input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      @include tablet-min {
        width: 20rem;
      }
    }
    &__button {
      // Based on body/field to mimic input
      margin-left: 1.25rem;
      @include mobile-only {
        margin-top: 0.75rem;
        margin-left: 0;
        width: 100%;
      }
    }
    &__social {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-wrap: wrap;
      margin-top: 1.5rem;
      @include tablet-min {
        flex-wrap: nowrap;
      }
      &-label {
        @include overline;
        color: rgba($c-dark, 0.6);
        display: inline-block;
        text-align: center;
        @include tablet-min {
          margin-right: 2.625rem;
        }
      }
      &-buttons {
        width: 100%;
        margin-top: math.div(1rem, 1.6);
        display: flex;
        align-items: center;
        justify-content: center;
        @include tablet-min {
          width: auto;
          margin-top: 0;
        }
      }
      &-button {
        &:not(:first-child) {
          margin-left: 2.625rem;
        }
      }
    }
    &__bottom {
      position: relative;
      padding: 1rem 0;
      @include tablet-min {
        // Solves non-optimal positioning in tablet landscape and larger
        // modes, and also fixes IE not supporting margin auto with flex.;
        position: absolute;
        bottom: 1rem;
        right: 0;
        left: 0;
        padding: 1rem;
      }
      &-logos {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
        margin-bottom: 2.25rem;
        height: 5rem;
        overflow: hidden;
        margin-left: -1rem;
        margin-right: -1rem;
      }
      &-logo {
        position: relative;
        margin: 0 1.75rem 0 1.75rem;
        fill: $c-dark;
        max-width: 15.5rem;
        max-height: 4rem;
        height: 4rem;
        flex-shrink: 0;
        margin-bottom: 1rem;

        @include mobile-only {
          margin: 0 1.25rem 1rem 1.25rem;
        }

        @include mobile-xs-only {
          margin: 0 1rem 1rem 1rem;
        }

        > svg {
          height: 100%;
        }

        &--sneaky-steve {
          max-height: 90%;
        }

        &--zalando {
          max-height: 50%;

          @include mobile-to-tablet {
            display: none;
          }
        }

        &--nakd {
          max-height: 45%;

          @include mobile-only {
            display: none;
          }
        }

        &--axel-arigato {
          max-height: 45%;

          @include mobile-to-tablet {
            display: none;
          }
        }

        &--hm {
        }

        &--ralph-lauren {
          top: -7%;

          @include mobile-only {
            display: none;
          }
        }

        &--relaxo {
          max-height: 44%;

          @include mobile-only {
            display: none;
          }
        }

        &--vera-bradley {
          top: -10%;

          @include mobile-only {
            display: none;
          }
        }

        &--arkk {
          max-height: 75%;
          top: 10%;
        }

        &--eytys {
          max-height: 83%;
        }
      }
      &-text {
        display: block;
        /*margin-bottom: 2.5rem;*/
        margin-bottom: 1rem;
      }
      &-scroll {
        @include caption--small;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 4rem;
        padding: 0rem 0.5rem 1rem 0.5rem;
        margin: 0 auto 1rem;
        cursor: pointer;

        @include mobile-only {
          display: none;
        }

        &:after {
          content: '';
          position: relative;
          top: 0.25rem;
          display: inline-block;
          border: solid $c-dark;
          border-width: 0 1px 1px 0;
          padding: math.div(0.5rem, 1.6);
          transform: rotate(45deg);
          transition: top 0.2s ease;
        }

        &:hover {
          font-weight: 600;

          &:after {
            top: 0.375rem;
          }
        }
      }
    }
  }

  .search {
    display: block;
    padding: 1rem 0;

    &__heading {
      @include heading--large;
      text-align: center;
      margin: 4vmin auto 0.4375rem;
      padding: 0 1rem;
    }
    &__subheading {
      @include subtitle;
      text-align: center;
      margin-bottom: 2rem;
      padding: 0 1rem;
      @include mobile-only {
        margin-top: 0.75rem;
      }
    }
  }
  .categories {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    max-width: 87.5rem;
    padding: 0;
    margin: 0 auto;
    list-style: none;
    &__item {
      @include body;
      width: 50%;
      padding: 0 0.5rem;
      margin: 1.5rem 0 0;
      text-align: center;
      @include tablet-min {
        width: 25%;
        margin: 2.125rem 0 0;
      }
      @include tablet-landscape-min {
        margin: 3rem 0 0;
      }
    }
    &__link {
      color: $c-dark;
    }
    &__img {
      width: 80%;
      margin: 0 auto;
      transition: transform 0.5s ease;
    }
    &__link:hover .categories__img {
      transform: scale(1.05);
    }
    &__title {
      display: block;
      margin-top: 1.25rem;
      opacity: 0.8;
      transition: opacity 0.5s ease;
    }
    &__link:hover .categories__title {
      opacity: 1;
    }
  }

  .process {
    position: relative;
    margin: 10vmin -1rem 0 -1rem;
    padding: 0 1rem;
    text-align: center;
    &__container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      /*min-height: calc(100vh - 10rem);*/
      padding: 0rem 1rem 3rem;
    }
    &__heading {
      @include heading;
      text-align: center;
      margin: 3rem auto 0.4375rem;
      padding: 0 1rem;
    }

    &__subheading {
      @include subtitle;
      text-align: center;
      margin-bottom: 4rem;
      padding: 0 1rem;
      @include mobile-only {
        margin-top: 0.75rem;
      }
    }

    &__action {
      margin: 4vmin 0 5rem 0;
    }
  }

  .benefits {
    position: relative;
    margin: 10vmin -1rem 9vmin -1rem;
    padding: 6rem 1rem 9rem;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $c-bg-gray;
      transform: skewY(-1.65deg);
      z-index: -1;
    }

    &__heading {
      @include heading--large;
      text-align: center;
      margin-bottom: 0.4375rem;
      padding: 0 1rem;
    }

    &__subheading {
      @include subtitle;
      text-align: center;
      margin-bottom: 0rem;
      padding: 0 1rem;
      @include mobile-only {
        margin-top: 0.75rem;
      }
    }

    &__container {
      margin: 0 auto;
      padding: 0 1rem;
      max-width: 65rem;

      @include tablet-min {
        display: flex;
        justify-content: space-between;
      }
    }

    &__group {
      max-width: 27rem;
      text-align: center;
      margin: 0 auto;
      padding: 3.25rem 0 1rem;

      @include tablet-min {
        margin: 0 2rem;
        padding: 4.25rem 0 1rem;
      }
    }

    &__group-heading {
      @include heading--large;
      display: inline-block;
      position: relative;
      padding: 0.625rem 0;
      margin: 0;
      // Together with below, a lot of small adjustments to make it
      // better centered (due to uneven space before especially
      // capital B). The underline should be 1px before/after each
      // first/last letter;
      left: -0.0625rem;

      // Underline
      &:after {
        content: '';
        position: absolute;
        left: 0.125rem;
        right: -0.0625rem;
        bottom: (0.625rem - 0.25rem);
        height: 2px;
        background-color: $c-dark;
      }
    }

    &__list {
      list-style: none;
      padding: 0;
    }

    &__item {
      @include body;
      position: relative;
      padding: 0.625rem 0 0.625rem 2.875rem;
      display: flex;
      align-items: center;
      min-height: 4.39rem;
      text-align: left;
    }

    &__icon {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -0.625rem;
      height: 1.5rem;
      width: 1.5rem;
      fill: $c-dark;
    }

    &__group-button {
      margin-top: 1.25rem;
    }
  }

  .partners {
    position: relative;
    padding: 0 1rem 0;

    &__heading {
      @include heading;
      text-align: center;
      margin-bottom: 0.4375rem;
      padding: 6rem 1rem 0 1rem;

      &--big {
        @include heading--large;
        padding-top: 0;
      }
    }

    &__subheading {
      @include subtitle;
      text-align: center;
      margin-bottom: 0rem;
      padding: 0 1rem;
      @include mobile-only {
        margin-top: 0.75rem;
      }
    }

    &__container {
      margin: 3rem auto 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 1rem;
      max-width: 120rem; // Slightly above 4 in width;
    }

    &__partner {
      display: flex;
      flex-direction: column;
      width: 24rem;
      margin: calc(1rem + 1vmin) 2.75rem;

      &:hover .partners__image--on-top {
        opacity: 1;
      }
    }

    &__image {
      max-height: math.div(8.5rem, 1.6);
      transition: opacity 0.5s ease;

      @include desktop-max {
        transform: scale(0.8);
      }

      &--on-top {
        position: absolute;
        opacity: 0;
      }

      &-link {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 10%;
        height: 6rem;
      }
    }

    &__text {
      @include body;
      text-align: center;
      margin: 1.75rem 0 0 0;

      a {
        text-decoration: underline;
      }
    }

    &__link {
      @include link;
      align-self: flex-end;
      margin: 1.25rem 0 0 0;
      padding: 0.5rem;
    }
  }

  .knowledge-bank {
    position: relative;
    margin: 0 -1rem 6rem -1rem;
    padding: 1vmin 1rem 0;

    &__heading {
      @include heading--large;
      text-align: center;
      margin-bottom: 0.4375rem;
      padding: 0 1rem;
    }

    &__subheading {
      @include subtitle;
      text-align: center;
      margin-bottom: 0rem;
      padding: 0 1rem;
      @include mobile-only {
        margin-top: 0.75rem;
      }
    }

    &__cta {
      display: table;
      margin: 3rem auto 0;
    }
  }

  .swiper {
    &-container {
      margin: 2.5rem -1rem 0 -1rem;

      @include tablet-landscape-min {
        height: 32rem;
      }
    }

    // Mixin for each arrow/button;
    @mixin button($left: true) {
      // Variables;
      $button-margin: calc(4vw - 1rem);
      $button-margin--large: calc(12vw - 7.125rem);
      $scale--tiny: scale(0.3703, 0.43175);
      $scale--small: scale(0.4445, 0.52265);
      $scale: scale(0.5556, 0.659);
      $factor: 1;

      // Base styling;
      transform: $scale--tiny; // Scale to 10x19px;
      margin-top: math.div(-1rem, 1.6); // To adjust for decreased size through transform;
      opacity: 0.8;
      transition: transform 0.2s ease;
      box-sizing: content-box;
      // To create a bigger hit box;
      padding: 0 math.div(1.6rem, 1.6); // It´s scaled down anyway, so simpler to have it as px, fixed and pretty big;
      margin: 0 math.div(-1.6rem, 1.6); // To balance out the position distortion caused by above padding;

      @if $left {
        left: $button-margin;
        $factor: -1;
      } @else {
        right: $button-margin;
      }

      @include mobile-ls-min {
        transform: $scale--small; // Scale to 12x23px;
        margin-top: math.div(-1.2rem, 1.6); // To adjust for decreased size through transform;
      }

      @include tablet-min {
        transform: $scale; // Scale to 15x29px;
        margin-top: math.div(-1.5rem, 1.6); // To adjust for decreased size through transform;
      }

      @include desktop-min {
        @if $left {
          left: $button-margin--large;
        } @else {
          right: $button-margin--large;
        }
      }

      &:hover {
        opacity: 1;
        transform: $scale--tiny translateX(#{$factor * 0.25rem});

        @include mobile-ls-min {
          transform: $scale--small translateX(#{$factor * 0.375rem});
        }

        @include tablet-min {
          transform: $scale translateX(#{$factor * 0.375rem});
        }
      }
    }

    &-button-prev {
      @include button();
    }

    &-button-next {
      @include button(false);
    }
  }

  .slide {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: calc(40rem + 4vw);
      margin: 0 auto;
      padding: 0 calc(8vw);
      /*background-color: rgba(0, 0, 255, 0.1);*/

      @include tablet-landscape-min {
        flex-direction: row;
        height: 32rem;
        max-width: 84rem;
        padding: 0 6rem;
      }
    }

    &__image {
      max-width: 100%;

      @include tablet-landscape-min {
        max-height: 32rem;
      }

      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 2;

        @include tablet-landscape-min {
          height: 32rem;
        }
      }
    }

    &__info {
      padding-top: 1.5rem;
      flex-grow: 1;

      @include tablet-landscape-min {
        box-sizing: content-box;
        max-width: 22.5rem;
        padding: 1rem 1rem (1rem - 0.25rem) calc(4.5rem + 2vw);
      }
    }

    &__heading {
      @include heading--small;
      margin: 0;
    }

    &__intro {
      @include body--small;
      color: rgba($c-dark, 0.9);
      margin: 0.25rem 0 0.75rem;
    }

    &__link {
      @include link;
      display: inline-block;
      float: right;
      padding: 0.25rem;
    }
  }

  .category {
    box-sizing: content-box;
    max-width: 60rem;
    margin: 0 -1rem 0;
    padding: 3.625rem 0 1rem;

    @include mobile-ls-min {
      padding-right: 0.5vw;
      padding-left: 0.5vw;
      margin: 0 auto;
    }

    @include tablet-min {
      padding-right: 3vw;
      padding-left: 3vw;
    }

    @include tablet-landscape-min {
      max-width: 86rem;
      padding-top: 2rem;
      padding-bottom: 2.625rem;
    }

    &__heading {
      @include heading--small;
      display: inline-block;
      position: relative;
      padding: 0.625rem 0;
      margin: 0 0 0 2.5rem;
      // Together with below, a lot of small adjustments to make it
      // better centered (due to uneven space before especially
      // capital B). The underline should be 1px before/after each
      // first/last letter;

      // Underline
      &:after {
        content: '';
        position: absolute;
        left: 0.0625rem;
        right: -0.0625rem;
        bottom: (0.625rem - 0.25rem);
        height: 2px;
        background-color: $c-dark;
      }
    }

    &__articles {
      display: flex;
      margin: 1rem 0 0 0;

      @include mobile-only {
        flex-wrap: wrap;
      }
    }
  }

  .item {
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    box-shadow: $bs-medium;
    border-radius: $br-light;
    transition: box-shadow 0.2s ease;
    margin: 0 1rem;
    padding: 1.5rem 1.5rem (1.625rem + 1.25rem) 1.5rem; // 1.25rem for link height, since it's absolutely positioned we need to accomodate extra bottom space for that;

    &:hover {
      box-shadow: $bs-high-medium;
    }

    @include mobile-xs-only {
      box-shadow: none;
    }

    @include mobile-ls-only {
      max-width: calc(50% - 2rem);
    }

    &:last-child {
      @include tablet-portrait-only {
        display: none;
      }
    }

    &__image {
      background-size: cover;
      background-position: 50% 50%;
      background-color: $c-light-gray;

      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 73.3333333%;
      }

      @include mobile-xs-only {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }

    &__heading {
      @include heading--tiny;
      margin: 0;
    }

    &__intro {
      @include body--small;
      color: rgba($c-dark, 0.9);
      margin: 0.25rem 0 1.25rem;
    }

    &__link {
      @include link;
      display: inline-block;
      position: absolute;
      padding: 0.25rem;
      right: 1.5rem;
      bottom: 1.375rem;
    }
  }
}
</style>
